<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Sitios</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Sitios</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_sitio_export"
                        v-if="$store.getters.can('admin.sitios.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="$store.getters.can('admin.sitios.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        ID
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.id"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Código Alterno
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.codigo"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Nombre
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Sigla
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.sigla"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Departamento
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="filtros.departamento"
                          label="descripcion"
                          :options="listasForms.departamentos"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Ciudad
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="filtros.ciudad"
                          label="nombre"
                          :options="listasForms.ciudades"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Tipo Sitio
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_sitio"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipo_sitio in listasForms.tipo_sitios"
                            :key="tipo_sitio.numeracion"
                            :value="tipo_sitio.numeracion"
                          >
                            {{ tipo_sitio.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Cobro perdida Producto</th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in sitios.data" :key="item.id">
                      <td>{{ item.id }}</td>
                      <td>{{ item.codigo }}</td>
                      <td>{{ item.nombre }}</td>
                      <td>{{ item.sigla }}</td>
                      <td>
                        {{
                          item.departamento ? item.departamento.descripcion : ""
                        }}
                      </td>
                      <td>{{ item.ciudad.nombre }}</td>
                      <td>{{ item.nTipoSitio }}</td>
                      <td>
                        <div
                          class="row"
                          v-if="
                            $store.getters.can('admin.sitios.show') &&
                              item.notificacion_perdida_prod == 1
                          "
                        >
                          <div class="col-md-3"></div>
                          <div class="col-md-5">
                            <span
                              class="badge"
                              data-toggle="modal"
                              data-target="#modal-form-cobro"
                              style="cursor: pointer"
                              v-bind:class="[
                                item.notificacion_perdida_prod == 1
                                  ? 'badge-success'
                                  : 'badge-danger',
                              ]"
                              @click="
                                llenarModalCobro(
                                  item.id,
                                  item.notificacion_perdida_prod
                                )
                              "
                            >
                              {{
                                item.notificacion_perdida_prod == 1
                                  ? "ACTIVO"
                                  : "INACTIVO"
                              }}
                            </span>
                          </div>
                        </div>

                        <div class="row" v-else>
                          <div class="col-md-5">
                            <span
                              class="badge"
                              data-toggle="modal"
                              data-target="#modal-form-cobro"
                              style="cursor: pointer"
                              v-bind:class="[
                                item.notificacion_perdida_prod == 1
                                  ? 'badge-success'
                                  : 'badge-danger',
                              ]"
                              @click="
                                llenarModalCobro(
                                  item.id,
                                  item.notificacion_perdida_prod
                                )
                              "
                            >
                              {{
                                item.notificacion_perdida_prod == 1
                                  ? "ACTIVO"
                                  : "INACTIVO"
                              }}
                            </span>
                          </div>
                          <div class="col-md-5">
                            <span class="badge badge-info">
                              {{
                                item.fecha_venc_notif_perd_prod
                                  ? item.fecha_venc_notif_perd_prod
                                  : "Sin fecha"
                              }}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          data-toggle="modal"
                          data-target="#modal-form-estado"
                          style="cursor: pointer"
                          v-bind:class="[
                            item.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                          v-if="$store.getters.can('admin.sitios.show')"
                          @click="llenarModal(item.id, item.estado)"
                        >
                          {{ item.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                        <span
                          v-else
                          class="badge"
                          v-bind:class="[
                            item.estado == 1 ? 'badge-success' : 'badge-danger',
                          ]"
                        >
                          {{ item.estado == 1 ? "ACTIVO" : "INACTIVO" }}
                        </span>
                      </td>

                      <td style="width: 50px">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="$store.getters.can('admin.sitios.show')"
                            @click="edit(item)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="$store.getters.can('admin.sitios.delete')"
                            @click="destroy(item.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="sitios.total">
                  <p>
                    Mostrando del <b>{{ sitios.from }}</b> al
                    <b>{{ sitios.to }}</b> de un total:
                    <b>{{ sitios.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="sitios"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <SitioEstado ref="SitioEstado" />
      </div>
    </div>
    <SitioExport ref="SitioExport" />

    <!-- Modal -->
    <div class="modal fade" id="modal-form-cobro">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cancelar cobro</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="estado">Estado</label>
              <select
                id="estado"
                disabled
                class="form-control form-control-sm"
                v-model="form_cobro.estado"
                :class="
                  $v.form_cobro.estado.$invalid ? 'is-invalid' : 'is-valid'
                "
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="estado in listasForms.estados"
                  :key="estado.numeracion"
                  :value="estado.numeracion"
                >
                  {{ estado.descripcion }}
                </option>
              </select>
              <div class="error" v-if="!$v.form_cobro.estado.required">
                Seleccione un estado
              </div>
            </div>
            <div class="form-group">
              <label for="Fecha">Fecha</label>
              <input
                type="date"
                class="form-control form-control-sm"
                id="fecha_cobro"
                placeholder="Fecha Cobro"
                v-model="form_cobro.fecha"
                :disabled="form_cobro.estado == 1"
                :class="
                  $v.form_cobro.fecha.$invalid ? 'is-invalid' : 'is-valid'
                "
                @input="validaFecha"
              />
              <div class="error" v-if="!$v.form_cobro.fecha.required">
                Ingrese una Fecha
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form_cobro.$invalid"
              @click="saveCancelarCobro()"
            >
              Guardar
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import SitioEstado from "./SitioEstado";
import vSelect from "vue-select";
import SitioExport from "./SitioExport";
import { required } from "vuelidate/lib/validators";

export default {
  name: "Sitios",
  components: {
    pagination,
    vSelect,
    SitioEstado,
    SitioExport,
  },
  data() {
    return {
      filtros: {
        id: null,
        nombre: null,
        codigo: null,
        sigla: null,
        departamento: null,
        ciudad: null,
        estado: null,
        tipo_sitio: null,
      },
      sitios: {},
      listasForms: {
        departamentos: [],
        ciudades: [],
        estados: [],
        tipo_sitios: [],
      },
      form_cobro: {
        id: null,
        estado: 2,
        fecha: null,
      },
    };
  },
  validations() {
    if (this.form_cobro.estado != 1) {
      return {
        form_cobro: {
          fecha: { required },
          estado: { required },
        },
      };
    } else {
      return {
        form_cobro: {
          estado: { required },
          fecha: {},
        },
      };
    }
  },
  methods: {
    getIndex(page = 1) {
      axios
        .get("/api/admin/sitios?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.sitios = response.data;
        });
    },
    getEstados() {
      axios.get("/api/lista/1").then((response) => {
        this.listasForms.estados = response.data;
      });
    },
    getTipoSitios() {
      axios.get("/api/lista/18").then((response) => {
        this.listasForms.tipo_sitios = response.data;
      });
    },
    getDepartamentos() {
      axios.get("/api/admin/departamentos/lista").then((response) => {
        this.listasForms.departamentos = response.data;
      });
    },
    getCiudades() {
      axios.get("/api/admin/ciudades/lista").then((response) => {
        this.listasForms.ciudades = response.data;
      });
    },
    destroy(idSitio) {
      this.$swal({
        title: "Esta seguro de eliminar este Sitio?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, ElimInar!",
      }).then((result) => {
        // <--
        if (result.value) {
          axios.delete("/api/admin/sitios/" + idSitio).then(() => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino el Sitio exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    llenarModal(id, estado) {
      if (this.$store.getters.can("admin.sitios.show")) {
        this.$refs.SitioEstado.llenar_modal(id, estado);
      }
    },

    create() {
      return this.$router.push({
        name: "/Admin/SitiosForm",
        params: { accion: "Crear" },
      });
    },

    edit(sitio) {
      return this.$router.push({
        name: "/Admin/SitiosForm",
        params: { accion: "Editar", data_edit: sitio },
      });
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    validaFecha() {
      const fechaCobro = new Date(this.form_cobro.fecha);
      const hoy = new Date();

      if (hoy >= fechaCobro) {
        this.form_cobro.fecha = null;
        this.$swal({
          icon: "error",
          title: `La fecha debe ser mayor a la fecha actual...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
    llenarModalCobro(sitio_id, estado) {
      this.form_cobro.fecha = null;
      this.form_cobro.id = sitio_id;
      if (estado == 1) {
        this.form_cobro.estado = 2;
      } else {
        this.form_cobro.estado = 1;
      }
    },

    saveCancelarCobro() {
      if (!this.$v.form_cobro.$invalid) {
        this.cargando = true;
        axios({
          method: "POST",
          url: "/api/admin/sitios/cancelarCobro",
          data: this.form_cobro,
        })
          .then((response) => {
            this.cargando = false;
            this.getIndex();
            this.$refs.closeModal.click();
            (this.form_cobro = {
              id: null,
              estado: 2,
              fecha: null,
            }),
              this.$swal({
                icon: "success",
                title:
                  "Se cambio el cobro de perdida de producto satisfactoriamente para el sitio...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
  },

  mounted() {
    this.getIndex();
    this.getEstados();
    this.getDepartamentos();
    this.getCiudades();
    this.getTipoSitios();
    this.getMsg();
  },
};
</script>
